import { ChakraProvider } from '@chakra-ui/react';
import { env } from 'env';
import { User } from 'oidc-client-ts';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from 'Context';
import { theme } from 'components/theme';
import { App } from './components/App';

const root = createRoot(document.getElementById('root') as HTMLElement);
const authority = env.REACT_APP_OIDC_AUTHORITY || '';
const clientId = env.REACT_APP_OIDC_CLIENTID || '';
const redirectUrl = env.REACT_APP_OIDC_REDIRECT_URL || '';
const oidcConfig = {
    authority,
    client_id: clientId,
    redirect_uri: redirectUrl,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSigninCallback: (_user: User | void): void => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
    },
    post_logout_redirect_uri: redirectUrl,
};

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <AuthProvider {...oidcConfig}>
                <BrowserRouter>
                    <AppContextProvider>
                        <App />
                    </AppContextProvider>
                </BrowserRouter>
            </AuthProvider>
        </ChakraProvider>
    </React.StrictMode>
);

import * as React from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    Stack,
    Text,
    HStack,
    Center,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useOneRoom } from 'api/Hooks';

import { RoomChartView } from './RoomChartView';

export const RoomDetail: React.FC = () => {
    const [value, setValue] = React.useState('Kvarter');
    const { roomId } = useParams();
    const { data: roomData } = useOneRoom(roomId);

    const MS_PER_DAY = 86400000;

    // default: display the last 15 minutes
    const MS_PER_MINUTE = 60000;

    const startDate15 = JSON.stringify(
        new Date(Date.now() - 15 * MS_PER_MINUTE)
    ).replace(/"/g, '');

    const startDate24t = JSON.stringify(
        new Date(Date.now() - MS_PER_DAY)
    ).replace(/"/g, '');

    const startDate7d = JSON.stringify(
        new Date(Date.now() - 7 * MS_PER_DAY)
    ).replace(/"/g, '');

    return (
        <Box>
            <Center>
                <Stack isInline>
                    <RadioGroup
                        value={value}
                        onChange={(nextValue: string) => setValue(nextValue)}
                    >
                        <Text>Vis siste</Text>
                        <HStack align='start'>
                            <Radio
                                backgroundColor='white'
                                value='Kvarter'
                                fontSize='32px'
                            >
                                Kvarter
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Døgn'
                                fontSize='32px'
                            >
                                Døgn
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Uke'
                                fontSize='32px'
                            >
                                Uke
                            </Radio>
                        </HStack>
                    </RadioGroup>
                </Stack>
            </Center>

            <Box paddingTop='64px' paddingBottom='24px'>
                {value === 'Kvarter' && (
                    <RoomChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate15}
                    />
                )}
                {value === 'Døgn' && (
                    <RoomChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate24t}
                    />
                )}
                {value === 'Uke' && (
                    <RoomChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate7d}
                    />
                )}
            </Box>
        </Box>
    );
};

import * as React from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    Stack,
    Text,
    HStack,
    // Accordion,
    // AccordionButton,
    // AccordionItem,
    // AccordionPanel,
    Center,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useOneRoom } from 'api/Hooks';

// import { SleepPhasesChart } from 'components/common/charts/SleepPhasesChart';
// import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { SleepChartView } from './SleepChartView';

export const SleepDetail: React.FC = () => {
    const [value, setValue] = React.useState('Døgn');
    const { roomId } = useParams();
    const { data: roomData } = useOneRoom(roomId);

    const MS_PER_DAY = 86400000;

    // default: display the last 24 hours3
    const startDate24t = JSON.stringify(
        new Date(Date.now() - MS_PER_DAY)
    ).replace(/"/g, '');

    const startDate7d = JSON.stringify(
        new Date(Date.now() - 7 * MS_PER_DAY)
    ).replace(/"/g, '');

    const startDate1m = JSON.stringify(
        new Date(Date.now() - 30 * MS_PER_DAY)
    ).replace(/"/g, '');

    return (
        <Box>
            <Center>
                <Stack isInline>
                    <RadioGroup
                        value={value}
                        onChange={(nextValue: string) => setValue(nextValue)}
                    >
                        <Text>Vis siste</Text>
                        <HStack align='start'>
                            <Radio
                                backgroundColor='white'
                                value='Døgn'
                                fontSize='32px'
                            >
                                Døgn
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Uke'
                                fontSize='32px'
                            >
                                Uke
                            </Radio>
                            <Radio
                                backgroundColor='white'
                                value='Måned'
                                fontSize='32px'
                            >
                                Måned
                            </Radio>
                        </HStack>
                    </RadioGroup>
                </Stack>
            </Center>
            <Box paddingTop='64px' marginBottom='40px'>
                {value === 'Døgn' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate24t}
                        lastDay
                    />
                )}
                {value === 'Uke' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate7d}
                        lastDay={false}
                    />
                )}
                {value === 'Måned' && (
                    <SleepChartView
                        roomId={roomId}
                        data={roomData}
                        startDate={startDate1m}
                        lastDay={false}
                    />
                )}
            </Box>
            {/* <Accordion marginBottom='40px' allowToggle marginTop='64px'>
                <AccordionItem>
                    <AccordionButton color='staySafe.blue'>
                        <Text marginRight='12px'>Vis tabell</Text>
                        <ArrowUpDownIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <Box
                            bg='white'
                            marginTop='16px'
                            padding='16px'
                            minWidth='1000px'
                        >
                            <SleepPhasesChart
                                roomId={roomId}
                                chartTitle='Søvnfaser siste døgn'
                                yAxisUnit='i prosent'
                                yAxisMax={100}
                                sensorIdMainRoom={roomData?.sensorIdMainRoom}
                                sensorIdBathRoom={roomData?.sensorIdBathRoom}
                                startDate={startDate24t}
                                type='SLEEPSTAGE'
                            />
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion> */}
        </Box>
    );
};

import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import '@fontsource/open-sans/400.css';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { RoomList } from './pages/RoomList/RoomList';
import OidcRedirect from './pages/OidcRedirect';
import { RegisterObservation } from './pages/Observations/RegisterObservation';
import { RoomDetailOverview } from './pages/RoomDetail/RoomDetailOverview';
import PrivateRoute from './common/utils/PrivateRoute';
import { Layout } from './pages/Layout';

export const App: React.FC = () => {
    const auth = useAuth();

    useEffect(() => {
        if (auth.user && auth.user.expired) {
            auth.signinRedirect();
        }
    }, [auth]);

    return !auth.isLoading ? (
        <Routes>
            <Route element={<Layout />}>
                {auth.user ? (
                    <>
                        <Route path='/' element={<PrivateRoute />}>
                            <Route path='/' element={<RoomList />} />
                        </Route>
                        <Route path='/room/:roomId' element={<PrivateRoute />}>
                            <Route
                                path='/room/:roomId'
                                element={<RoomDetailOverview />}
                            />
                        </Route>
                        <Route
                            path='/observation/:roomId'
                            element={<PrivateRoute />}
                        >
                            <Route
                                path='/observation/:roomId'
                                element={<RegisterObservation />}
                            />
                        </Route>
                    </>
                ) : (
                    <>{auth.signinRedirect()}</>
                )}
                <Route path='/oauth-redirect' element={<OidcRedirect />} />
            </Route>
        </Routes>
    ) : (
        <></>
    );
};

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function OidcRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    });

    // eslint-disable-next-line react/react-in-jsx-scope
    return <></>;
}
export default OidcRedirect;

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { RoomDto } from 'models/dtos/RoomDto';
import { SleepStagesChart } from 'components/common/charts/SleepStagesChart';
import { SleepReport } from 'components/common/measurements/SleepReport';
import { SleepPhasesChart } from 'components/common/charts/SleepPhasesChart';

interface propType {
    roomId: string | undefined;
    data: RoomDto | undefined;
    startDate: string;
    lastDay: boolean;
}

const SleepChartView: React.FC<propType> = ({
    roomId,
    data,
    startDate,
    lastDay,
}) => {
    return (
        <VStack spacing='16px'>
            <Box bg='white' padding='16px' width='100%'>
                <SleepStagesChart
                    roomId={roomId}
                    type='SLEEPSTAGE'
                    chartTitle='Søvnstadier'
                    yAxisUnit='i prosent'
                    yAxisMax={100}
                    sensorIdMainRoom={data?.sensorIdMainRoom}
                    sensorIdBathRoom={data?.sensorIdBathRoom}
                    startDate={startDate}
                />
                {lastDay && (
                    <SleepReport
                        startDate={startDate}
                        sensorIdBathRoom={data?.sensorIdBathRoom}
                        sensorIdMainRoom={data?.sensorIdMainRoom}
                    />
                )}
            </Box>

            <Box bg='white' padding='16px' width='100%'>
                <SleepPhasesChart
                    roomId={roomId}
                    chartTitle='Søvn'
                    yAxisUnit='i prosent'
                    yAxisMax={100}
                    type='SLEEPSTAGE'
                    sensorIdMainRoom={data?.sensorIdMainRoom}
                    sensorIdBathRoom={data?.sensorIdBathRoom}
                    startDate={startDate}
                />
            </Box>
        </VStack>
    );
};

export { SleepChartView };
